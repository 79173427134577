// import './tikiHtmx'
const htmx = require('htmx.org')

// eslint-disable-next-line no-underscore-dangle
window._hyperscript = require('hyperscript.org').browserInit()

window.htmx = htmx
window.htmx.config.useTemplateFragments = true

function setTimezoneCookie() {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  document.cookie = `browser.timezone=${tz};path=/; SameSite=None; Secure`
}

setTimezoneCookie()
